<!--
 * @Author: hwu
 * @Date: 2020-09-28 14:39:08
 * @Description: 订单支付页
 * @LastEditTime: 2024-04-29 11:54:48
-->

<template>
  <div :class="{ 'pad-container': isPad }">
    <template v-if="isPad">
      <div class="app-container pad-container_left"></div>
      <div class="pad-container_divider"></div>
    </template>
    <div class="app-container" :class="{ 'pad-container_right': isPad }">
      <div :style="commentFixed && serviceType === 5 ? `paddingBottom:${49 + commentHeight}px` : ''" :class="[{ 'page-content-comment-fixed': commentFixed }, 'page-content']">
        <div class="order-header">
          <div class="order-header-service">
            <div class="order-header-service_box" :style="{ width: serviceBoxWidth }">
              <div class="order-header-service_item" :class="{ active: serviceType === item.code, disabled: order.serviceType === 5 }" v-for="(item, index) in serviceTypeOptions" :key="index" @click="changeServiceType(item.code)">
                <span class="order-header-service_item-text">{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="order-header-table" v-if="kdsModel !== '2' && order.tableName">
            <span>桌号：{{ order.tableName }}</span>
          </div>
        </div>
        <div class="order-item" v-if="order.items">
          <div class="order-item_list">
            <div class="single-item-box" v-for="(item, index) in order.items" :key="index" v-show="(index < 4 || moreItemShow) && item.selected === 1">
              <div class="single-item-right">
                <div class="item-info">
                  <div class="item-info_name">{{ item.itemName }}</div>
                  <div class="item-info_price">{{ item.price | priceFilter }}</div>
                  <div class="item-info_qty">x{{ item.quantity }}</div>
                </div>
                <div class="item-desc" v-if="item.spec || item.make || item.taste || item.promoTag">
                  <span class="item-desc_text" v-if="item.spec">{{ item.spec }}</span>
                  <span class="item-desc_text" v-if="item.make">{{ item.make }}</span>
                  <span class="item-desc_text" v-if="item.taste">{{ item.taste }}</span>
                  <span class="item-desc_tag" v-if="item.promoTag && item.discountAmount > 0">{{ item.promoTag }}，已优惠{{ item.discountAmount }}元</span>
                </div>
                <div class="detail-selected" :class="{ active: item.detailShow }" v-if="(item.subCharges && item.subCharges.length > 0) || (item.subItems && item.subItems.length > 0)">
                  <div class="detail-selected-box" @click="toggleCartItemDetail(item)">
                    <div class="detail-selected-content">
                      <div class="result-content">
                        <span class="result-item" v-for="(sub, subIndex) in item.subItems" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                        <span class="result-item" v-for="(sub, subIndex) in item.subCharges" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                      </div>
                      <div class="collapse-btn">
                        <i class="iconfont iconfangxiang-xiangxia"></i>
                      </div>
                    </div>
                  </div>
                  <div class="detail-selected-box_fixed" @click="toggleCartItemDetail(item)">
                    <div class="detail-selected-content">
                      <div class="result-content">
                        <span class="result-item" v-for="(sub, subIndex) in item.subItems" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                        <span class="result-item" v-for="(sub, subIndex) in item.subCharges" :key="subIndex">{{ sub | cartSubItemFilter }}</span>
                      </div>
                      <span class="collapse-btn">
                        <i class="iconfont iconfangxiang-xiangshang"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-item_more" @click="handleMoreItem" v-if="order.items.length > 4">
            <span class="more-text">{{ moreItemShow ? '收起' : '展开更多' }}</span>
            <i class="more-icon iconfont" :class="[moreItemShow ? 'iconfangxiang-xiangshang' : 'iconfangxiang-xiangxia']"></i>
          </div>
          <div class="order-item">
            <div class="order-item_list">
              <div class="single-item-box" v-for="(baseFee, baseFeeIndex) in order.baseFeeInfos" :key="baseFeeIndex" v-show="order.baseFeeInfos.length > 0">
                <div class="single-item-right">
                  <div class="item-info">
                    <div class="item-info_name">
                      <span>{{ baseFee.feeName }}</span>
                      <span class="item-name_desc" v-if="baseFee.feeType === 1">（每人{{ baseFee.feePrice }}元）</span>
                      <span class="item-name_desc" v-if="baseFee.feeType === 2">每桌{{ baseFee.feePrice }}元</span>
                    </div>
                    <div class="item-info_price">￥{{ baseFee.feePrice }}</div>
                    <div class="item-info_qty">
                      <el-select class="item-info-qty-select" v-model="guestCount" @change="changeOrderGuestCount">
                        <el-option class="item-info-qty-select_item" v-for="item in guestCountArray" :key="item" :label="item" :value="item"> {{ item }}位 </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="item-desc" v-if="baseFee.feeComment">
                    <span class="item-desc_text">{{ baseFee.feeComment }}</span>
                  </div>
                </div>
              </div>
              <div class="single-item-box" v-if="order.serviceFee > 0">
                <div class="single-item-right">
                  <div class="item-info">
                    <div class="item-info_name">
                      <span>服务费</span>
                      <span class="item-name_desc">（消费金额的{{ order.serviceRate }}%）</span>
                    </div>
                    <div class="item-info_price">￥{{ order.serviceFee }}</div>
                  </div>
                  <div class="item-desc" v-if="order.serviceComments">
                    <span class="item-desc_text">{{ order.serviceComments }}</span>
                    <!-- <span class="item-desc_text">（消费金额的{{order.serviceRate}}%）</span> -->
                  </div>
                </div>
              </div>
              <div class="single-item-box" v-if="order.boxFee > 0 && serviceType === 2">
                <div class="single-item-right">
                  <div class="item-info">
                    <div class="item-info_name">
                      <span>打包费</span>
                    </div>
                    <div class="item-info_price">￥{{ order.boxFee }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="order-item_amount">
            <span class="amount-title">总金额：</span>
            <span class="amount-value">￥{{ order.shouldAmount }}</span>
          </div>
        </div>

        <!-- 活动、券 -->
        <payment-promo-box :order="order" :isPaying="submitDisabled" @onOrderUpdate="refreshOrderInfo" />

        <div :class="[{ 'order-amount-comment-fixed': commentFixed }, 'order-amount-comment']">
          <div class="order-amount-comment-row">
            <div class="order-comment" @click="commentPopupShow = true">
              <div class="order-comment_left">
                <div class="comment-title">写备注/查看备注</div>
              </div>
            </div>
            <div class="order-amount">
              <span class="amount-title">应付金额：</span>
              <span class="amount-value">￥{{ order.actualAmount }}</span>
            </div>
          </div>
          <!-- 抖音外卖 -->
          <template v-if="serviceType === 5">
            <div class="order-row">
              <div class="order-row-label">
                <span class="left-title">抖音外卖流水号</span>
                <span class="left-title color-warning margin-left-8">【{{ dyOrderNum }}】</span>
              </div>
              <!-- <div class="order-row-value">
              <span class="order-number color-warning">{{dyOrderNum}}</span>
            </div> -->
            </div>
            <!-- <div class="desc-row">通过“抖音”门店账号可查看订单的流水号</div> -->
            <div class="order-row no-border" @click="showAddressPopup">
              <div class="order-row-label">
                <div class="left-box" v-if="editedAddress && editedAddress.consigneeAddress">
                  <div class="left-title">
                    {{ editedAddress.consigneeAddress }}
                  </div>
                  <div class="left-desc">{{ editedAddress | infoTextFilter }}</div>
                </div>
                <div class="order-left-title" v-else>
                  <span class="copy-text">请填写收货人信息*</span>
                  <div class="copy-btn" @click.stop="copyText">
                    <span class="btn-text">点击可粘贴</span>
                  </div>
                </div>
              </div>
              <div class="order-row-value">
                <i class="iconfont iconjiantou-xiangyou"></i>
              </div>
            </div>
          </template>
          <div class="comment-bottom"></div>
        </div>
      </div>

      <div class="page-footer">
        <div class="btn-box">
          <button class="btn-item left-btn" @click="linkToMenu()">继续点餐</button>
        </div>
        <div class="btn-box">
          <!-- payModel=2是后买单 -->
          <button v-if="order.payModel === 2 || serviceType === 5" class="btn-item right-btn" @click="saveOrder()">确认下单</button>
          <button v-else class="btn-item right-btn" @click="saveOrder()">支付下单</button>
        </div>
      </div>
      <!-- loading提示 -->
      <loading-box :show="loadingShow"></loading-box>
      <!-- 外带，堂食选择弹窗 -->
      <payment-service-type-dialog :show="serviceTypeDialogShow" @onClose="changeServiceType" />
      <!-- 单品备注弹窗 -->
      <payment-comment-popup :show="commentPopupShow" :orderId="orderId" @onClose="commentPopupShow = false" @onUpdate="updateComment"></payment-comment-popup>
      <!-- pos机扫码支付弹窗 -->
      <payment-pos-dialog :show="posDialogShow" :orderId="orderId" :payAmount="order.actualAmount" :needInputMobile="needInputMobile" :payModel="order.payModel" :brandCode="order.brandCode" @onClose="posDialogShow = false"></payment-pos-dialog>
      <!-- 共同点餐支付成功通知弹窗 -->
      <payment-success-dialog :show="successDialogShow" :userName="payerName" :orderId="orderId" />
      <!-- pos机提示顾客支付中弹窗 -->
      <dialog-box :show="posCustomScanPayingDialogShow" title="顾客支付中..." rightBtnText="返回" @onRightClick="posUpdateOrder">
        <div slot="body">
          <p class="">如顾客需要加减菜品或更改支付方式，可【返回】修改订单！</p>
        </div>
      </dialog-box>
      <!-- pos机提示顾客超时未完成支付 -->
      <dialog-box :show="posCustomScanPayOutOfTimeDialogShow" title="顾客超时未完成支付..." :showLeftBtn="true" leftBtnText="本单作废" rightBtnText="继续支付" @onLeftClick="invalidOrder" @onRightClick="continuePay">
        <div slot="body">
          <p class="">请选择作废本单还是重新给顾客下单?</p>
        </div>
      </dialog-box>
      <!-- 扫桌码提醒 -->
      <dialog-box :show="needTableDialogShow" title="温馨提示" rightBtnText="去扫码" @onBackdropClick="needTableDialogShow = false" @onRightClick="scanTableQrCode">
        <div slot="body">
          <p>请扫顾客就坐的桌码，方便送餐。</p>
        </div>
      </dialog-box>
      <!-- 获取顾客手机号，用于短信提醒 -->
      <dialog-box title="请输入顾客手机号" :show="needMobileDialogShow" :showLeftBtn="true" leftBtnText="关闭" rightBtnText="完成" @onLeftClick="closeNeedMobileDialog" @onRightClick="confirmSetCustomMobile">
        <div slot="body">
          <div class="form-box">
            <div class="form-item">
              <div class="form-item_label">手机号</div>
              <div class="form-item_content">
                <div class="content-row">
                  <form action="" @submit.prevent="$closeKeyboard()">
                    <input type="tel" v-model="customMobile" class="row_input" placeholder="请输入手机号" />
                  </form>
                </div>
              </div>
            </div>
            <div class="form-item no-border">
              <div class="form-item_desc">*手机号用于给顾客发送取餐提醒</div>
            </div>
          </div>
        </div>
      </dialog-box>
      <!-- 验券核销提醒 -->
      <div class="coupon-verification-dialog" v-show="showCouponVerification">
        <div class="verification-dialog-title">系统验券规则</div>
        <div class="verification-dialog-body">
          当点击
          <span>【支付下单】</span>
          之后，不论是否完成支付。验证的优惠券都会被核销！ 核销后不退券、不兑现、不找零！
        </div>
        <div class="verification-dialog-footer">
          <button @click="confirmCouponVerification">知道了</button>
        </div>
      </div>
      <payment-order-confirm-dialog :show="orderConfirmShow" :orderId="order.orderId" :tableId="order.tableId || ''" :tableName="order.tableName" :serviceType="Number(serviceType)" @onConfirm="orderConfirm" @scanTable="rescanTable" />
      <!--    抖音外卖收货地址弹框-->
      <payment-address-popup :show.sync="paymentAddressPopupShow" :orderId="order.orderId" popupTiTle="收货人信息" :addressData="editedAddress" @onConfirm="confirmSelectAddress" />
      <!--    抖音外卖下单，地址二次确认弹框-->
      <dialog-box :show="showConfirmAddressDialog" title="请确定" showLeftBtn leftBtnText="取消" rightBtnText="确定" @onLeftClick="showConfirmAddressDialog = false" @onRightClick="addressConfirm">
        <div slot="body">
          <div class="dialog-row">
            <div class="label">抖音外卖:</div>
            <div class="value">【{{ dyOrderNum }}】</div>
          </div>
          <div class="dialog-row">
            <div class="label">订单金额：</div>
            <div class="value">￥{{ order.actualAmount }}</div>
          </div>
          <div class="dialog-row">
            <div class="label">收货人信息：</div>
            <div class="value">{{ editedAddress.consigneeAddress }} | {{ editedAddress | infoTextFilter }}</div>
          </div>
          <div class="dialog-row margin-top-16">
            <div class="label">确定后请在“抖音”外卖通知取货。</div>
          </div>
        </div>
      </dialog-box>

      <!-- 遮罩 -->
      <transition name="wh-backdrop">
        <div class="wh-dialog-backdrop" v-show="showBackUp"></div>
      </transition>
    </div>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui'
import AddressParse from 'zh-address-parse'
import { mapState } from 'vuex'
import LoadingBox from '@/components/common/LoadingBox'
import PaymentPromoBox from './PaymentPromoBox'
import PaymentCommentPopup from './PaymentCommentPopup.vue'
import PaymentPosDialog from './PaymentPosDialog.vue'
import PaymentSuccessDialog from './PaymentSuccessDialog.vue'
import PaymentServiceTypeDialog from './PaymentServiceTypeDialog.vue'
import DialogBox from '@/components/common/DialogBox'
import PaymentOrderConfirmDialog from './PaymentOrderConfirmDialog'
import PaymentAddressPopup from './PaymentAddressPopup'
import validate from '@/utils/validate'
import { getOrderDetailApi, changeServiceTypeApi, changeOrderGuestCountApi, invalidOrderApi, getOrderForPrintBillApi, getOrderForPrintMenuApi, setTableInfoApi, setCustomMobileApi, saveOrderAfterPayApi, saveOrderForDouYinTakeOutApi } from '@/api/order'
import { payByZeroApi } from '@/api/pay'
import { connectSocketApi, closeSocketApi, subscribeOrderDetailApi, subscribeOrderSuccessApi, subscribeCustomScanPayOutOfTimeApi, subscribeStartCustomScanPayApi } from '@/api/socket'
import { posUpdateOrderApi } from '@/api/cart'
import { printBillApi, printMenuApi, scanQrCodeApi, createScanDeviceConnectionApi, broadenWebViewApi } from '@/api/apk'
export default {
  components: {
    LoadingBox,
    PaymentPromoBox,
    PaymentCommentPopup,
    PaymentPosDialog,
    PaymentSuccessDialog,
    PaymentServiceTypeDialog,
    elSelect: Select,
    elOption: Option,
    DialogBox,
    PaymentOrderConfirmDialog,
    PaymentAddressPopup
  },
  data() {
    return {
      loadingShow: true,
      serviceType: 1,
      serviceTypeOptions: [
        {
          code: 1,
          name: '堂食'
        },
        {
          code: 2,
          name: '外带'
        }
      ],
      dyServiceTypeOptions: {
        code: 5,
        name: '外卖'
      },
      orderId: '',
      order: {
        items: []
      },
      guestCountArray: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      moreItemShow: false,
      commentPopupShow: false,
      isExistsComment: false,
      posDialogShow: false,
      submitDisabled: false,
      successDialogShow: false,
      payerName: '',
      guestCount: '',
      serviceTypeDialogShow: false,
      posCustomScanPayingDialogShow: false,
      posCustomScanPayOutOfTimeDialogShow: false,
      showCouponVerification: false,
      showBackUp: false,
      commentFixed: false,
      needTableDialogShow: false,
      needMobileDialogShow: false,
      customMobile: '',
      orderConfirmShow: false,
      dyOrderNum: '',
      paymentAddressPopupShow: false,
      editedAddress: {},
      // addressPopupTitle: '',
      commentHeight: '',
      needConfirmAddress: false,
      showConfirmAddressDialog: false
    }
  },
  filters: {
    priceFilter(val) {
      if (val === 0) {
        return '免费'
      }
      return '￥' + val
    },
    cartSubItemFilter(val) {
      if (!val.itemName) {
        return ''
      }
      let descList = []
      if (val.spec) {
        descList.push(val.spec)
      }
      if (val.make) {
        descList.push(val.make)
      }
      if (val.taste) {
        descList.push(val.taste)
      }
      let descStr = ''
      if (descList.length > 0) {
        descStr = `(${descList.join(' ')})`
      }
      return `· ${val.itemName}${descStr} x ${val.quantity}`
    },
    infoTextFilter(editedAddress) {
      let sexText = ''
      if (editedAddress.sex === 1 || editedAddress.consigneeSex === 1) {
        sexText = '先生'
      } else if (editedAddress.sex === 2 || editedAddress.consigneeSex === 2) {
        sexText = '女士'
      }
      return `${editedAddress.name || editedAddress.consigneeName} ${sexText} ${editedAddress.mobile || editedAddress.consigneeMobile}`
    }
  },
  computed: {
    ...mapState('order', ['userId', 'entranceType', 'cartId', 'storeId', 'tableId', 'kdsModel', 'isPad', 'afterPayBackUrl']),
    serviceBoxWidth() {
      return this.serviceTypeOptions.length * 56 + 'px'
    },
    needScanTable() {
      // 已经绑定桌位了，不需要再扫了
      if (this.order.tableId) {
        return false
      }
      // 如果是自取模式，则不需要扫桌码
      if (this.kdsModel === '2') {
        return false
      }
      // 如果是送餐到桌模式的外带，则不需要扫桌码
      if (this.serviceType === 2) {
        return false
      }
      // 如果是后买单的送餐到桌的堂食，则必须扫码
      if (this.order.payModel === 2) {
        return true
      }
      // 如果是送餐到桌模式的堂食, 但是订单内所有商品都是“自取商品”或者“扫条码加购进来的无需制作的”（表示柜台点餐的时候客户直接拿走了），也不需要扫桌码
      if (this.order.items && this.order.items.every((x) => x.ownTakeStatus || (x.isScan && !x.isMake))) {
        return false
      }

      // 剩下的情况就是：如果是送餐到桌模式的堂食，服务员点餐，需要扫桌码
      return true
    },
    needInputMobile() {
      // 已经有手机号了，不需要再录入了
      // if (this.order.mobile) {
      //   return false
      // }
      // 如果是自取模式，肯定需要客户手机号，用于取餐通知
      if (this.kdsModel === '2') {
        return true
      }
      // 如果是送餐到桌模式的堂食，则不需要录入顾客手机号（不需要通知取餐）
      if (this.serviceType === 1) {
        return false
      }
      // 服务员下单的外带，不需要输入手机号
      if (this.serviceType === 2) {
        return false
      }
      // 如果是送餐到桌模式的外带, 但是订单内所有商品都是“扫条码加购进来的无需制作的”（表示柜台点餐的时候客户直接拿走了），也不需要客户手机号
      if (this.order.items && this.order.items.every((x) => x.isScan && !x.isMake)) {
        return false
      }

      return true
    }
  },
  created() {
    this.orderId = this.$route.query.orderId
    if (!this.orderId) {
      this.$toast('订单异常，请重新扫码下单')
    }
    // 检查点餐环境
    this.$checkEntranceType(this.entranceType)
    // 获取订单详情
    this.getOrderDetail(1)
    // 链接websocket
    this.connectSocket()
  },
  beforeDestroy() {
    // this.closeSocket()
  },
  methods: {
    getOrderDetail(source) {
      getOrderDetailApi(this.orderId, this.userId).then((res) => {
        if (res.status !== 0 || !res.data) {
          this.$linkToError('点餐超时，请重新扫码下单')
          return false
        }
        this.loadingShow = false
        if (!res.data.items) {
          res.data.items = []
        }
        this.order = res.data
        if (this.order) {
          this.serviceType = this.order.serviceType
        }
        this.parseGuestCount()
        // 抖音外卖
        if (this.order.serviceType === 5) {
          this.serviceTypeOptions.push(this.dyServiceTypeOptions)
          this.dyOrderNum = this.order.orderNum
          this.needConfirmAddress = true
          // 选择抖音外卖下单，录入了收货地址信息，点击继续点餐，再回到这里，才会出现接口返回consigneeInfo的情况
          if (this.order.consigneeInfo) {
            this.editedAddress = this.order.consigneeInfo
          }
        } else if (source) {
          // 不是抖音外卖，到支付页，需要选择外带堂食
          this.serviceTypeDialogShow = true
        }
        this.$nextTick(() => {
          this.getCommentPostion()
        })
      })
    },
    refreshOrderInfo(val) {
      // 如果传回来的订单信息不为空，则直接赋值，否则，刷新接口
      if (val) {
        this.order = JSON.parse(JSON.stringify(val))
      } else {
        this.getOrderDetail(false)
      }
    },
    changeServiceType(code) {
      if (this.order.serviceType === 5) {
        return false
      }
      this.serviceTypeDialogShow = false
      if (code === this.serviceType) {
        return false
      }
      changeServiceTypeApi(this.orderId, this.userId, code).then((res) => {
        this.order = res.data
        this.serviceType = this.order.serviceType
      })
    },
    changeOrderGuestCount(count) {
      if (count === this.order.guestCount) {
        return false
      }
      changeOrderGuestCountApi(this.orderId, this.userId, count).then((res) => {
        this.order = res.data
        this.parseGuestCount()
      })
    },
    transferUserImage(url) {
      if (!url) {
        return require('@/assets/images/icon_head_error.png')
      }
      return url
    },
    parseGuestCount() {
      this.guestCount = 'x' + this.order.guestCount + '位'
    },
    toggleCartItemDetail(item) {
      if (!item.detailShow) {
        this.order.items.forEach((x) => {
          x.detailShow = false
        })
      }
      item.detailShow = !item.detailShow
      this.order = JSON.parse(JSON.stringify(this.order))
    },
    updateComment(val) {
      this.isExistsComment = val
    },
    posUpdateOrder() {
      posUpdateOrderApi(this.order.cartId)
      this.posCustomScanPayingDialogShow = false
      this.linkToMenu()
    },
    invalidOrder() {
      invalidOrderApi(this.order.orderId)
      this.posCustomScanPayOutOfTimeDialogShow = false
      this.redirectToPosOrderGuide()
    },
    continuePay() {
      this.getOrderDetail()
      this.posCustomScanPayOutOfTimeDialogShow = false
    },
    closeWindow() {
      this.$closeWindow()
    },
    linkToMenu() {
      const menuRouterName = this.isPad ? 'OrderPadMenu' : 'OrderMenu'
      this.$router.push({
        name: menuRouterName
      })
    },
    handleMoreItem() {
      this.moreItemShow = !this.moreItemShow
      this.$nextTick(() => {
        this.getCommentPostion()
      })
    },
    addressConfirm() {
      this.showConfirmAddressDialog = false
      this.needConfirmAddress = false
      this.saveOrder()
    },
    saveOrder() {
      // 没有商品的空订单不能提交
      const selectedItems = this.order.items.filter((x) => x.selected === 1)
      if (!selectedItems || selectedItems.length === 0) {
        if (!this.order.baseFeeInfos || this.order.baseFeeInfos.length <= 0) {
          // 如果是后买单的加单，商品为空的时候，直接跳到买单页
          if (this.order.payModel === 2 && this.order.mergeOrderId) {
            this.$router.push({
              name: 'OrderPaymentAfterPay',
              query: { orderId: this.order.mergeOrderId }
            })
            return false
          }
          this.$toast('请先加购商品')
          return false
        }
      }
      // 抖音外卖下单
      if (this.serviceType === 5) {
        if (!this.dyOrderNum) {
          this.$toast('请输入单号')
          return false
        }
        if (!this.editedAddress || (!this.editedAddress.mobile && !this.editedAddress.consigneeMobile)) {
          this.$toast('请添加收货地址')
          return false
        }
        // 抖音外卖地址二次确认
        if (this.needConfirmAddress) {
          this.showConfirmAddressDialog = true
          return false
        }
        saveOrderForDouYinTakeOutApi(this.order.orderId, this.userId, this.dyOrderNum).then((res) => {
          if (res.status !== 0) {
            this.$toast(res.msg)
            return false
          }
          this.$toast({
            message: '下单成功',
            duration: 1500,
            forbidClick: true,
            onClose: () => {
              this.redirectToPosOrderGuide()
              this.printBill()
            }
          })
        })
        return false
      }
      this.checkCouponBeforePay()
    },
    checkCouponBeforePay() {
      if (this.order.promoInfos && this.order.promoInfos.some((x) => x.selected === 1 && (x.promoType === 20 || x.promoType === 21))) {
        this.showCouponVerification = true
        this.showBackUp = true
        return false
      }
      this.checkTableAndMobileBeforePay()
    },
    confirmCouponVerification() {
      this.showCouponVerification = false
      this.showBackUp = false
      this.checkTableAndMobileBeforePay()
    },
    checkTableAndMobileBeforePay() {
      if (this.needScanTable) {
        this.needTableDialogShow = true
        return false
      }
      //  这里只有0元支付的时候，在这个页面弹输入手机号弹窗，其他支付方式，在后面弹
      if (this.needInputMobile && this.order.actualAmount === 0) {
        this.needMobileDialogShow = true
        return false
      }
      this.payOrder()
    },
    // 创建扫码枪链接，并绑定扫码回调时间
    createScanTableConnection() {
      if (this.$bappSdk.isBApp()) {
        window.scanCallBack = this.setTableInfo
      }

      // 开启扫码枪连接
      createScanDeviceConnectionApi('1', '')
      // 监听扫码回调通知
      window.addEventListener('message', this.scanTableCallBack, false)
    },
    closeScanConnection() {
      // 关闭扫码枪连接
      createScanDeviceConnectionApi('0', '')
      // 关闭扫码回调通知
      window.scanCallBack = null
      window.removeEventListener('message', this.scanTableCallBack, false)
    },
    // 主动触发扫一扫
    scanTableQrCode() {
      this.createScanTableConnection()
      scanQrCodeApi()
    },
    // 后买单的确认订单弹框的回调，打开扫码弹框，默认扫码
    rescanTable() {
      this.orderConfirmShow = false
      this.needTableDialogShow = true
      this.scanTableQrCode()
    },
    // 扫码回调
    scanTableCallBack(event) {
      if (event.data.state !== 'scanCallBack') {
        return false
      }
      const url = event.data.code
      this.setTableInfo(url)
    },
    setTableInfo(url) {
      this.closeScanConnection()
      const path = url.split('/')
      if (!path) {
        this.$toast('无效的桌码')
        return false
      }
      const codeId = path[path.length - 1]
      setTableInfoApi(this.orderId, this.userId, codeId).then((res) => {
        if (res.status !== 0) {
          this.$toast('请扫本店的点餐码')
          return false
        }
        this.needTableDialogShow = false
        this.getOrderDetail()
        this.payOrder()
      })
    },
    closeNeedMobileDialog() {
      this.needMobileDialogShow = false
      this.payOrder()
    },
    confirmSetCustomMobile() {
      if (!validate.mobile(this.customMobile)) {
        this.$toast('请输入正确的手机号')
        return false
      }
      setCustomMobileApi(this.orderId, this.userId, this.customMobile).then((res) => {
        if (res.status !== 0) {
          this.$toast('保存失败')
          return false
        }
        this.needMobileDialogShow = false
        this.payOrder()
      })
    },
    payOrder() {
      if (this.submitDisabled) {
        return false
      }
      // 如果是后买单模式，直接下单(先选择加单还是独立订单，再调用下单接口)
      if (this.order.payModel === 2) {
        // 如果本单已经是加单了（后买单店员点买单然后继续点餐），那么就不用弹窗了
        if (this.order.mergeOrderId) {
          this.orderConfirm(this.order.mergeOrderId)
          return false
        }
        this.orderConfirmShow = true
        return false
      }
      if (this.order.actualAmount === 0) {
        this.payByZero()
        return false
      }
      // pos-web，目前只保留员工pos机（app)下单
      this.posDialogShow = true
    },
    payByZero() {
      const paramVo = {
        orderId: this.orderId,
        userId: this.userId
      }
      this.submitDisabled = true
      payByZeroApi(paramVo).then((res) => {
        if (res.status !== 0) {
          this.submitDisabled = false
          this.$toast(res.msg)
          this.getOrderDetail()
          return false
        }
        this.$toast('下单成功')
        setTimeout(() => {
          this.redirectToPosOrderGuide()
          this.printBill()
          this.submitDisabled = false
        }, 2000)
      })
    },
    redirectToPosOrderGuide() {
      if (this.order.payModel === 2 && this.afterPayBackUrl) {
        window.location.href = this.afterPayBackUrl
        // 如果是pad点餐，那么就拓宽webview
        if (this.isPad) {
          broadenWebViewApi('phone')
        }
        return
      }
      this.$router.push({
        name: 'OrderGuide',
        query: {
          storeId: this.storeId,
          userId: this.userId,
          entranceType: this.entranceType,
          isPad: this.isPad ? 1 : 0
        }
      })
    },
    printBill() {
      getOrderForPrintBillApi(this.orderId).then((res) => {
        if (res.status !== 0) {
          return false
        }
        const orderList = res.data
        if (this.hasPrinted) {
          return false
        }
        this.hasPrinted = true
        printBillApi(JSON.stringify(orderList))
      })
    },
    printMenu(mergeOrderId) {
      // 2023.02.27:统一传订单Id
      // 如果是合单，就按合单的订单Id来打印，如果是首单，就按当前订单来打印
      // const printOrderId = mergeOrderId || this.orderId
      getOrderForPrintMenuApi(this.orderId).then((res) => {
        if (res.status !== 0) {
          return false
        }
        const orderList = res.data
        if (this.hasPrinted) {
          return false
        }
        this.hasPrinted = true
        printMenuApi(JSON.stringify(orderList))
      })
    },
    connectSocket() {
      connectSocketApi(this.cartId, this.userId).then(
        () => {
          subscribeOrderDetailApi(this.subscribeOrderDetail)
          subscribeOrderSuccessApi(this.subscribeOrderSuccess)
          subscribeCustomScanPayOutOfTimeApi(this.subscribeCustomScanPayOutOfTime)
          subscribeStartCustomScanPayApi(this.subscribeStartCustomScanPay)
        },
        (err) => {
          console.log(err)
        }
      )
    },
    closeSocket() {
      closeSocketApi()
    },
    subscribeOrderDetail(orderDetail) {
      this.order = orderDetail
      this.serviceType = this.order.serviceType
    },
    subscribeOrderSuccess(user) {
      this.payerName = user.nickName || ''
      // 如果是pos下单，只有当是客扫的时候，才会显示支付成功的弹窗
      if (this.order.isCustomScanPay !== 1) {
        return false
      }
      this.posCustomScanPayingDialogShow = false
      this.successDialogShow = true
    },
    subscribeCustomScanPayOutOfTime() {
      if (this.order.isCustomScanPay === 1) {
        this.posCustomScanPayOutOfTimeDialogShow = true
        this.posCustomScanPayingDialogShow = false
      }
    },
    subscribeStartCustomScanPay() {
      this.posDialogShow = false
      this.posCustomScanPayingDialogShow = true
      this.getOrderDetail()
    },
    /**
     * 后买单订单确认回调
     */
    orderConfirm(mergeOrderId) {
      this.orderConfirmShow = false
      saveOrderAfterPayApi(this.order.orderId, this.userId, mergeOrderId).then((res) => {
        if (res.status !== 0) {
          this.$toast('下单失败')
          return false
        }
        this.$toast({
          message: '下单成功',
          duration: 3000,
          forbidClick: true,
          onClose: () => {
            this.printMenu(mergeOrderId)
            this.redirectToPosOrderGuide()
          }
        })
      })
    },
    getCommentPostion() {
      // 获取备注栏位置，判断是否需要固定底部
      const data = document.querySelector('.comment-bottom').getBoundingClientRect()
      const windowSize = document.querySelector('body').getBoundingClientRect()
      if (this.serviceType === 5) {
        // 获取底部order-amount-comment的高度
        let boxData = document.querySelector('.order-amount-comment').getBoundingClientRect()
        this.commentHeight = boxData.height
      }
      if (data.bottom + 49 > windowSize.height) {
        // 备注栏被遮挡
        this.commentFixed = true
      }
    },
    copyText() {
      if (!this.editedAddress.consigneeAddress) {
        navigator.clipboard
          .readText()
          .then((text) => {
            if (text) {
              let index = -2
              let filterText = ''
              // 抖音后台辅助过来的地址，包含性别信息，且性别前面有一个空格。
              // 所以根据字符串是否包含先生或女士，且前面有一个空格来判断性别和截取字符串。
              // 例如淘宝复制过来的地址是没有性别信息的，默认性别是男
              if (text.indexOf('先生') !== -1) {
                if (!text[text.indexOf('先生') - 1].trim()) {
                  index = text.indexOf('先生')
                  let newText1 = text.slice(0, index)
                  let newText2 = text.slice(index + 2)
                  filterText = this.replaceBracket(newText1 + newText2)
                } else {
                  filterText = this.replaceBracket(text)
                }
                this.editedAddress.sex = 1
              } else if (text.indexOf('女士') !== -1) {
                if (!text[text.indexOf('女士') - 1].trim()) {
                  index = text.indexOf('女士')
                  let newText1 = text.slice(0, index)
                  let newText2 = text.slice(index + 2)
                  filterText = this.replaceBracket(newText1 + newText2)
                } else {
                  filterText = this.replaceBracket(text)
                }
                this.editedAddress.sex = 2
              } else {
                this.editedAddress.sex = 1
                filterText = this.replaceBracket(text)
              }
              this.editedAddress.address = this.reverseReplaceBracket(AddressParse(filterText).province)
              this.editedAddress.addressName = `${this.reverseReplaceBracket(AddressParse(filterText).city)}${this.reverseReplaceBracket(AddressParse(filterText).area)}`
              this.editedAddress.detailAddress = this.reverseReplaceBracket(AddressParse(filterText).detail)
              this.editedAddress.mobile = AddressParse(filterText).phone
              this.editedAddress.name = AddressParse(filterText).name
              this.paymentAddressPopupShow = true
            } else {
              this.showAddressPopup()
            }
          })
          .catch((err) => {
            console.error('Failed to read clipboard contents: ', err)
            this.showAddressPopup()
          })
      } else {
        this.showAddressPopup()
      }
    },
    // 替换括号（正则需要优化）
    replaceBracket(str) {
      let newStr = ''
      newStr = str.replace(/[(]/g, 'leftBracket')
      newStr = newStr.replace(/[（]/g, 'leftBracket')
      newStr = newStr.replace(/[)]/g, 'rightBracket')
      newStr = newStr.replace(/[）]/g, 'rightBracket')
      return newStr
    },
    // 还原括号（正则需要优化）
    reverseReplaceBracket(str) {
      let newStr = ''
      newStr = str.replace(/leftBracket/g, '(')
      newStr = newStr.replace(/rightBracket/g, ')')
      return newStr
    },
    showAddressPopup() {
      if (this.editedAddress && this.editedAddress.consigneeAddress) {
        const arr = this.editedAddress.consigneeAddress.split(' ')
        this.editedAddress.address = arr[0]
        this.editedAddress.addressName = arr[1]
        this.editedAddress.detailAddress = arr[2]
        this.editedAddress.mobile = this.editedAddress.consigneeMobile
        this.editedAddress.name = this.editedAddress.consigneeName
        this.editedAddress.sex = this.editedAddress.consigneeSex || 1
        // this.addressPopupTitle = '编辑收货地址'
      } else {
        // this.addressPopupTitle = '新增收货地址'
      }
      this.paymentAddressPopupShow = true
    },
    confirmSelectAddress(formData) {
      this.editedAddress = formData
      this.paymentAddressPopupShow = false
      this.$nextTick(() => {
        this.getCommentPostion()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page-content {
  width: 100%;
  height: 100vh;
  padding-top: 80px;
  padding-bottom: 124px;
  overflow-y: auto;
  &.page-content-comment-fixed {
    padding-bottom: 190px;
  }
  // -webkit-overflow-scrolling: touch;
}
.page-footer {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 98px;
  padding: 0 12px;
  align-items: center;
  background-color: $color-white;
  box-shadow: 0 -2px 8px 0 rgba($color-black, 0.1);
  box-sizing: border-box;
  z-index: 99;
  .btn-box {
    position: relative;
    flex: 1 1 50%;
    padding: 0 12px;
    font-size: 30px;
    .btn-item {
      width: 100%;
      height: 72px;
      line-height: 64px;
      border: 4px solid $color-primary;
      border-radius: 8px;
      &:focus {
        outline: 0;
      }
      &.left-btn {
        color: $color-primary;
        background-color: $color-white;
      }
      &.right-btn {
        color: $color-white;
        background-color: $color-primary;
        position: relative;
        span {
          position: absolute;
          right: 24px;
          font-size: 24px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
.order-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 80px;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  z-index: 2;
  padding: 0 24px;
  .order-header-service {
    flex: 1;
    .order-header-service_box {
      display: flex;
      height: 48px;
      display: flex;
      align-items: center;

      .order-header-service_item {
        width: 112px;
        height: 48px;
        border-top: 1px solid $color-primary;
        border-bottom: 1px solid $color-primary;
        background: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        .order-header-service_item-text {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: $color-primary;
        }
        &:first-of-type {
          border: 1px solid $color-primary;
          border-radius: 28px 0 0 28px;
        }
        &:last-of-type {
          border: 1px solid $color-primary;
          border-radius: 0 28px 28px 0;
        }
        &.active {
          background: $color-primary !important;
          .order-header-service_item-text {
            color: $color-white !important;
          }
        }
        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }
  .order-header-together {
    position: relative;
    flex: 0;
    display: inline-block;
    height: 36px;
    padding: 0 7px;
    line-height: 30px;
    color: $color-primary;
    font-weight: 300;
    text-align: right;
    white-space: nowrap;
    border: 1px solid $color-primary;
    border-radius: 28px;
    .btn-text {
      display: inline-block;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      transform: scale(0.8);
      transform-origin: center;
    }
    .btn-tag {
      position: absolute;
      display: inline-block;
      top: -8px;
      right: -8px;
      min-width: 44px;
      height: 44px;
      line-height: 44px;
      font-size: 24px;
      text-align: center;
      color: $color-white;
      background-color: $color-danger;
      border-radius: 50%;
      transform: scale(0.5);
      transform-origin: top right;
    }
  }
  .order-header-table {
    flex: 0;
    margin-left: 24px;
    color: $color-text-main;
    font-size: 28px;
    font-weight: bold;
    white-space: nowrap;
  }
  .order-type_item {
    flex: 1;
    padding: 24px;
    text-align: center;
    .item-radio {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-text-sub;
      &.active {
        color: $color-primary;
      }
      .item-radio_icon {
        flex: 0 0 36px;
        margin-right: 16px;
        font-size: 0;
        svg {
          width: 36px;
          height: 36px;
          vertical-align: center;
        }
      }
      .item-radio_name {
        position: relative;
        flex: 0 0 auto;
        font-size: 28px;
        white-space: nowrap;
      }
    }
  }
}
.order-item {
  width: 100%;
  background-color: $color-white;
  .order-item_list {
    width: 100%;
    transition: all 30s;
    .single-item-box {
      display: flex;
      width: 100%;
      padding: 24px;
      align-items: center;
      .single-item-left {
        flex: 0 0 56px;
        margin-right: 16px;
        font-size: 0;
        img {
          width: 56px;
          height: 56px;
          border-radius: 50%;
        }
      }
      .single-item-right {
        flex: 1;
        .item-info {
          display: flex;
          align-items: center;
          .item-info_name {
            flex: 1;
            font-size: 28px;
            color: $color-text-main;
            .item-name_desc {
              margin-left: 10px;
              line-height: 30px;
              font-size: 24px;
              color: $color-text-sub;
            }
          }
          .item-info_price {
            flex: 0;
            font-size: 28px;
            color: $color-text-main;
            white-space: nowrap;
          }
          .item-info_qty {
            flex: 0;
            min-width: 120px;
            text-align: right;
            font-size: 28px;
            color: $color-text-main;
            &.disabled {
              opacity: 0.5;
            }
            .item-info-qty-select_disabled {
              color: $color-primary;
              opacity: 0.7;
            }
          }
        }
        .item-desc {
          width: 100%;
          margin-top: 8px;
          .item-desc_text {
            margin-right: 10px;
            line-height: 30px;
            font-size: 24px;
            color: $color-text-sub;
          }
          .item-desc_tag {
            display: inline-block;
            font-size: 24px;
            padding: 4px 8px;
            color: $color-danger;
            border: 1px solid $color-danger;
            border-radius: 6px;
            transform: scale(0.66);
            transform-origin: center left;
          }
        }
      }
    }
  }
  .order-item_more {
    position: relative;
    width: 100%;
    padding: 24px;
    text-align: center;
    font-size: 24px;
    color: $color-text-sub;
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      height: 1px;
      border-bottom: 1px solid $color-border;
    }
    .more-icon {
      margin-left: 10px;
      font-size: 28px;
    }
  }
  .order-item_amount {
    width: 100%;
    padding: 24px;
    text-align: right;
    font-size: 30px;
    color: $color-text-main;
    .amount-value {
      margin-left: 36px;
      font-weight: bold;
    }
  }
}
.detail-selected {
  position: relative;
  width: 100%;
  margin-top: 8px;
  font-size: 24px;
  &.active {
    .detail-selected-box {
      visibility: hidden;
    }
    .detail-selected-box_fixed {
      display: block;
      max-height: 500px;
      opacity: 1;
    }
  }
  .detail-selected-box {
    position: relative;
    width: 100%;
    .detail-selected-content {
      position: relative;
      display: flex;
      width: 100%;
      line-height: 30px;
      padding: 16px 42px 16px 12px;
      background-color: #f3f6fa;
      .result-content {
        height: 30px;
        line-height: 30px;
        overflow: hidden;
      }
    }
    .collapse-btn {
      position: absolute;
      top: 16px;
      right: 30px;
      z-index: 11;
    }
  }
  .detail-selected-box_fixed {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    z-index: 10;
    opacity: 0;
    transition: all 0.5s;
    .detail-selected-content {
      position: relative;
      display: flex;
      width: 100%;
      padding: 16px 12px 48px 12px;
      line-height: 36px;
      background-color: #f3f6fa;
      .collapse-btn {
        position: absolute;
        bottom: 10px;
        right: 30px;
        z-index: 11;
      }
    }
  }
  .result-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .result-item {
      flex: 0 0 auto;
      padding: 0 12px;
      margin-bottom: 8px;
      white-space: nowrap;
    }
  }
}
.order-amount-comment {
  &.order-amount-comment-fixed {
    position: fixed;
    right: 0;
    bottom: 96px;
    width: 750px;
    background-color: $color-white;
    border-top: 1px solid $color-border;
    z-index: 2;
    .order-comment,
    .order-amount {
      padding-top: 0;
    }
  }
  .order-amount-comment-row {
    display: flex;
  }
  .order-comment {
    flex: 1;
    display: flex;
    width: 100%;
    padding: 24px;
    margin-top: 16px;
    align-items: center;
    font-size: 30px;
    background-color: $color-white;
    .order-comment_left {
      flex: 1;
      .comment-title {
        color: $color-primary;
      }
    }
  }
  .order-amount {
    flex: 1;
    width: 100%;
    padding: 24px;
    margin-top: 16px;
    text-align: right;
    font-size: 30px;
    color: $color-text-main;
    background-color: $color-white;
    .amount-value {
      margin-left: 36px;
      font-weight: bold;
      color: $color-danger;
    }
  }
  .order-row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background-color: $color-white;
    &:not(:last-child):not(.no-border)::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      height: 1px;
      border-bottom: 1px solid $color-border;
    }
    .order-row-label {
      .left-title {
        font-size: 28px;
        color: $color-text-main;
      }
      .left-desc {
        margin-top: 8px;
        font-size: 24px;
        color: $color-text-normal;
      }
      .order-left-title {
        display: flex;
        align-items: center;
        .copy-text {
          font-size: 28px;
          line-height: 36px;
          color: $color-text-main;
        }
        .copy-btn {
          margin-left: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          line-height: 38px;
          padding: 0 4px;
          color: $color-brand;
          font-weight: 300;
          text-align: right;
          white-space: nowrap;
          border: 1px solid $color-brand;
          border-radius: 28px;
          .btn-text {
            display: inline-block;
            font-size: 24px;
            font-style: normal;
            font-weight: 300;
            transform: scale(0.8);
            transform-origin: center;
          }
        }
      }
    }
    .order-row-value {
      margin-left: 32px;
      .row_input {
        width: 200px;
        margin-left: 16px;
        padding: 8px 16px;
        font-size: 26px;
        border: 1px solid $color-border;
        border-radius: 8px;
      }
      .iconjiantou-xiangyou {
        color: $color-primary;
      }
      .order-number {
        font-size: 28px;
        font-weight: bold;
      }
    }
  }
  .has-desc {
    padding-bottom: 8px;
    position: relative;
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      height: 0;
      border-bottom: 0 solid $color-border;
    }
  }
  .desc-row {
    position: relative;
    padding: 0 24px;
    background-color: $color-white;
    font-size: 24px;
    color: $color-text-sub;
    ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 24px;
      right: 24px;
      height: 1px;
      border-bottom: 1px solid $color-border;
    }
  }
}

.warning-tips {
  position: absolute;
  top: 100%;
  right: 50%;
  width: 240px;
  padding: 6px 10px;
  margin-right: -120px;
  font-size: 24px;
  text-align: center;
  color: $color-white;
  background-color: $color-warning;
  opacity: 0.8;
  border-radius: 8px;
  z-index: 11;
  transform: scale(0.8);
  transform-origin: center left;
  white-space: normal;
  &.no-warp {
    width: 400px;
    margin-right: -200px;
    white-space: nowrap;
  }
  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: 50%;
    width: 0;
    height: 0;
    margin-top: -7px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid rgba($color-warning, 0.9);
    transform: rotate(180deg);
    z-index: 11;
  }
}

.coupon-verification-dialog {
  position: absolute;
  bottom: 120px;
  background-color: white;
  width: 490px;
  right: 24px;
  padding: 16px;
  border-radius: 16px;
  z-index: 2001;
  .verification-dialog-title {
    padding: 16px 0;
    font-size: 30px;
    font-weight: 500;
    color: $color-warning;
  }
  .verification-dialog-body {
    font-size: 26px;
    font-weight: 400;
    color: $color-text-normal;
    span {
      font-weight: 500;
    }
  }
  .verification-dialog-footer {
    padding: 24px 0 18px;
    text-align: right;
    button {
      width: 128px;
      height: 58px;
      border: 0;
      background-color: $color-primary;
      // todo 6.4
      border-radius: 64px;
      color: white;
      font-size: 24px;
      font-weight: 500;
    }
  }
  &::before {
    content: '';
    position: absolute;
    bottom: -14px;
    right: 32px;
    width: 0;
    height: 0;
    margin-top: -7px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid rgba(white, 0.9);
    z-index: 11;
  }
}
.wh-dialog-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-dialog-enter-active,
.wh-dialog-leave-active {
  transition: opacity 0.5s;
}
.wh-dialog-enter,
.wh-dialog-leave-to {
  opacity: 0;
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 1s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}

/deep/ .item-info_qty .el-select {
  width: 80px;
  .el-input {
    .el-input__inner {
      text-align: right;
      border: none;
      height: 54px !important;
      line-height: 54px !important;
      padding: 0 0 0 10px;
      font-size: 28px;
      color: $color-primary;
      border-color: $color-primary;
      &:focus {
        border-color: $color-primary;
      }
    }
    &.is-focus .el-input__inner {
      border-color: $color-primary;
    }
    .el-input__suffix {
      right: 0;
      .el-input__icon {
        display: none;
      }
    }
  }
}

.form-box {
  width: 100%;
  .form-item {
    display: flex;
    width: 100%;
    padding: 24px 0;
    line-height: 36px;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }
    .form-item_label {
      flex: 0 0 140px;
      text-align: left;
      font-size: 30px;
      color: $color-text-main;
      white-space: nowrap;
    }
    .form-item_content {
      flex: 1;
      text-align: left;
      font-size: 30px;
      color: $color-text-normal;
      .content-row {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        .row_input {
          flex: 1;
          width: 100%;
          margin: 0 8px;
          font-size: 30px;
          border: none;
          border-radius: 8px;
        }
        .row_btn {
          flex: 0;
          min-width: 150px;
          padding: 10px;
          font-size: 24px;
          text-align: center;
          color: $color-primary;
          border: 1px solid $color-primary;
          border-radius: 8px;
          white-space: nowrap;
          &.disabled {
            opacity: 0.5;
          }
        }
      }
      .iconfont {
        font-size: 40px;
      }
    }
    .form-item_desc {
      flex: 0 0 100%;
      text-align: left;
      font-size: 26px;
      color: $color-text-sub;
    }
  }
}
.dialog-row {
  display: flex;
  padding: 2px 0;
  text-align: left;
  font-size: 28px;
  line-height: 36px;
  color: $color-text-main;
  .label {
    min-width: 6em;
    white-space: nowrap;
  }
  .value {
    color: $color-text-normal;
  }
}
</style>
