/*
 * @Author: hwu
 * @Date: 2021-02-22 17:30:01
 * @Description: file content
 * @LastEditTime: 2023-01-16 17:44:08
 */
import { centerHttp, mktHttp } from '@/utils/axios'
import { GenUniversalToken } from '@/utils/crypto'

/**
 * 发送验证码
 * @param mobile 验证码手机号
 * @param token token
 * @param isInside is inside
 * @returns {Promise}
 */
export const sendVerifyCodeApi = (mobile) => {
  const token = GenUniversalToken()
  const isInside = '0'
  return centerHttp.post(
    '/boss/sendMsg',
    {},
    {
      params: { mobile, token, isInside }
    }
  )
}

/**
 * 验证码验证
 * @param mobile mobile
 * @param code captcha
 * @returns {Promise}
 */
export const checkVerifyCodeApi = (mobile, code) => centerHttp.get(`/boss/verify/code/available?mobile=${mobile}&code=${code}`)

/**
 * 百度地图查询地址
 * @param {*} city
 * @param {*} address
 * @returns
 */
export const queryAddressApi = (city, address) => mktHttp.get(`/stores/init/storeLocation?city=${city}&address=${address}`)
