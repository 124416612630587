import CryptoJS from 'crypto-js' // 引用AES源码js

/**
 * 十六位十六进制数作为密钥
 */
const key = CryptoJS.enc.Utf8.parse('68616E6D61688882')

/**
 * 十六位十六进制数作为密钥偏移量
 */
const iv = CryptoJS.enc.Utf8.parse('68777568616E7E91')

const universalContent = 'hanma_center_hwu_2019'

/**
 * 加密方法
 * @return {string}
 */
const Encrypt = (word) => {
  const srcs = CryptoJS.enc.Utf8.parse(word)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.ciphertext.toString().toUpperCase()
}

/**
 * @return {string}
 */
export const GenUniversalToken = () => {
  const currentTime = new Date().getTime()
  const json = {
    content: universalContent,
    date: currentTime
  }
  const jsonStr = JSON.stringify(json)
  return Encrypt(jsonStr)
}
