<!--
 * @Author: lcz
 * @Date: 2023-01-16 14:39:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-28 21:42:07
 * @Description: 订单支付页——收货地址
-->
<template>
  <div>
    <!-- 录入收货地址弹框 -->
    <div class="wh-popup-wraper" @touchmove.stop>
      <transition name="wh-popup">
        <div class="wh-popup-box" :style="{ height: '80%' }" v-if="show">
          <div class="popup-body">
            <div class="popup-title">{{ popupTiTle }}</div>
            <div class="address-box">
              <!-- <van-cell-group> -->
              <van-field readonly v-model="editedAddress.addressName" @click="linkToMap" clickable label="收货地址*" placeholder="请选择收货地址">
                <template #right-icon>
                  <i class="iconfont iconjiantou-xiangyou"></i>
                </template>
              </van-field>
              <van-field v-model="editedAddress.detailAddress" label="门牌号*" placeholder="填写详细地址，如：6号101室"></van-field>
              <van-field v-model="editedAddress.name" label="联系人*" placeholder="姓名">
                <template #extra>
                  <div class="radio-box">
                    <div class="radio-item" :class="{ active: sex.value === editedAddress.sex }" v-for="(sex, index) in sexOptions" :key="index" @click="checkSex(sex.value)">
                      <i class="iconfont" :class="[sex.value === editedAddress.sex ? ' iconxuanzhong' : ' iconweixuanzhong']"></i>
                      <span>{{ sex.name }}</span>
                    </div>
                  </div>
                </template>
              </van-field>
<!--              <van-cell title="性别" class="radio-row">-->
<!--                <template #default>-->
<!--                  <div class="radio-box">-->
<!--                    <div class="radio-item" :class="{ active: sex.value === editedAddress.sex }" v-for="(sex, index) in sexOptions" :key="index" @click="checkSex(sex.value)">-->
<!--                      <i class="iconfont" :class="[sex.value === editedAddress.sex ? ' iconxuanzhong' : ' iconweixuanzhong']"></i>-->
<!--                      <span>{{ sex.name }}</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </van-cell>-->
              <van-field v-model="editedAddress.mobile" maxlength="11" type="digit" label="手机号" placeholder="收货人手机号" />
              <!-- </van-cell-group> -->
            </div>
            <btn-footer showLeftBtn leftBtnText="返回" rightBtnText="完成" @onLeftClick="closeAddPopup" @onRightClick="submitAddress"> </btn-footer>
          </div>
        </div>
      </transition>
      <transition name="wh-backdrop">
        <div class="wh-popup-backdrop" @click="onBackdropClick" v-if="show"></div>
      </transition>
    </div>
    <!-- 地址搜索弹框 -->
    <van-popup v-model="showMapPopupShow" position="bottom" :style="{ height: '100%' }">
      <div class="popup-body">
        <div class="popup-top">
          <van-search v-model="mapQueryName" @input="searchMap" @cancel="closeMapPopup" placeholder="请输入收货地址" show-action>
            <template #left>
              <div class="city-select" @click="showCityPopupShow">
                <i class="city-select_icon iconfont icondingwei"></i>
                <span class="city-select_text">{{ currentCity.value }}</span>
                <i class="city-select_icon iconfont iconfangxiang-xiangxia"></i>
              </div>
            </template>
          </van-search>
          <div class="address-box">
            <div class="map-list">
              <div class="map-item" v-for="(map, index) in queryMapList" :key="index" @click="confirmSelectMap(map)">
                <div class="map-item-name">{{ map.name }}</div>
                <div class="map-item-desc">{{ map.address }}</div>
              </div>
            </div>
            <div class="wh-empty-box" v-if="!queryMapList || queryMapList.length === 0">
              <img class="wh-empty-img" src="@/assets/images/empty-3.png" />
              <p class="wh-empty-text">请输入收货地址</p>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 城市选择 -->
    <popup-index-bar :show="cityPopupShow" :data="cityPopupData" @onClose="cityPopupShow = false" @onItemClick="confirmSelectCity"></popup-index-bar>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { Field, Cell, Popup, Search } from 'vant'
import validate from '@/utils/validate'
import BtnFooter from '@/components/common/BtnFooter'
import PopupIndexBar from '@/components/common/PopupIndexBar'
import { setConsigneeInfoApi } from '@/api/order'
import { queryAddressApi } from '@/api/member'
import { listBrandCitiesApi } from '@/api/store'
Vue.use(Field)
Vue.use(Cell)
Vue.use(Popup)
Vue.use(Search)
export default {
  name: 'PaymentAddressPopup',
  components: { BtnFooter, PopupIndexBar },
  props: {
    show: { type: Boolean, required: true, default: false },
    orderId: { type: String, required: true, default: '' },
    popupTiTle: { type: String, required: false, default: '新增收货地址' },
    addressData: { type: Object, required: false, default: () => {} }
  },
  data () {
    return {
      addPopupShow: false,
      mapPopupShow: false,
      addressList: [],
      selectedAddress: {},
      editedAddress: {
        mobile: '',
        sex: '',
        name: '',
        province: '',
        city: '',
        address: '',
        addressName: '',
        detailAddress: ''
      },
      sexOptions: [
        {
          name: '先生',
          value: 1
        },
        {
          name: '女士',
          value: 2
        }
      ],
      currentCity: {
        value: '上海市',
        key: 1020075
      },
      cityPopupShow: false,
      cityPopupData: [],
      mapQueryName: '',
      queryMapList: [],
      showMapPopupShow: false
    }
  },
  computed: {
    ...mapState('order', ['userId'])
  },
  watch: {
    show (val) {
      if (val) {
        this.editedAddress.address = this.addressData.address
        this.editedAddress.addressName = this.addressData.addressName
        this.editedAddress.detailAddress = this.addressData.detailAddress
        this.editedAddress.mobile = this.addressData.mobile
        this.editedAddress.sex = this.addressData.sex || 1
        this.editedAddress.name = this.addressData.name
        this.listBrandCities()
      }
    },
    mapPopupShow (val) {
      if (val) {
        if (this.show) {
          this.showMapPopupShow = true
        } else {
          this.showMapPopupShow = false
        }
      } else {
        this.showMapPopupShow = false
      }
    }
  },
  methods: {
    onConfirm (formData) {
      this.$emit('onConfirm', formData)
    },
    checkSex (sex) {
      this.editedAddress.sex = sex
    },
    closeAddPopup () {
      this.$emit('update:show', false)
    },
    validForm () {
      if (!this.editedAddress.addressName) {
        return '请选择收货地址'
      }
      if (!this.editedAddress.detailAddress) {
        return '请填写门牌号'
      }
      if (!this.editedAddress.name) {
        return '请填写联系人姓名'
      }
      if (!this.editedAddress.sex) {
        return '请选择性别'
      }
      if (!this.editedAddress.mobile) {
        return '请填写收货人手机号'
      }
      if (!validate.mobile(this.editedAddress.mobile)) {
        return '请填写正确的手机号'
      }
      return ''
    },
    validateAllEmpty () {
      if (!this.editedAddress.name && !this.editedAddress.sex && !this.editedAddress.mobile && !validate.mobile(this.editedAddress.mobile) && !this.editedAddress.addressName && !this.editedAddress.detailAddress) {
        return true
      } else {
        return false
      }
    },
    submitAddress () {
      const errMsg = this.validForm()
      if (errMsg !== '') {
        this.$toast(errMsg)
        return
      }
      // if (this.validateAllEmpty()) {
      //   this.closeAddPopup()
      //   return false
      // }
      const formData = {
        consigneeInfoId: '',
        consigneeName: this.editedAddress.name,
        consigneeMobile: this.editedAddress.mobile,
        consigneeSex: this.editedAddress.sex,
        consigneeAddress: `${this.editedAddress.address} ${this.editedAddress.addressName} ${this.editedAddress.detailAddress}`
      }
      setConsigneeInfoApi(this.orderId, this.userId, formData).then((res) => {
        if (res.status !== 0) {
          this.$toast('保存失败')
          return false
        }
        this.$toast('保存成功')
        this.onConfirm(formData)
      })
    },
    listBrandCities () {
      listBrandCitiesApi().then((res) => {
        this.cityPopupData = res.data
      })
    },
    showCityPopupShow () {
      this.mapPopupShow = false
      this.cityPopupShow = true
    },
    confirmSelectCity (val) {
      this.cityPopupShow = false
      this.currentCity = val
      this.mapPopupShow = true
    },
    linkToMap () {
      this.mapPopupShow = true
    },
    searchMap (value) {
      if (!this.mapQueryName) {
        return false
      }
      queryAddressApi(this.currentCity.value, this.mapQueryName).then((res) => {
        this.queryMapList = res || []
      })
    },
    closeMapPopup () {
      this.mapQueryName = ''
      this.queryMapList = []
      this.mapPopupShow = false
    },
    confirmSelectMap (map) {
      this.editedAddress.province = map.province
      this.editedAddress.city = map.city
      this.editedAddress.address = map.address
      this.editedAddress.addressName = map.name
      this.closeMapPopup()
    },
    onBackdropClick () {
      this.$emit('update:show', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.wh-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-white;
  border-radius: 32px 32px 0 0;
  z-index: 2001;
}
.wh-popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 2000;
}
.wh-popup-enter-active,
.wh-popup-leave-active {
  transition: transform 0.3s;
}
.wh-popup-enter,
.wh-popup-leave-to {
  transform: translateY(100%);
}
.wh-backdrop-enter-active,
.wh-backdrop-leave-active {
  transition: opacity 0.3s;
}
.wh-backdrop-enter,
.wh-backdrop-leave-to {
  opacity: 0;
}
.popup-body {
  width: 100%;
  height: 100%;
  padding: 80px 0 100px 0;
  overflow-y: auto;
  position: relative;
  background: $color-background;
  .popup-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    padding-left: 24px ;
    color: $color-text-main;
    font-size: 28px;
    font-weight: bold;
    line-height: 80px;
    background: $color-white;
  }
  .popup-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
  }
}
.address-box {
  width: 100%;
}
.radio-row {
  /deep/.van-cell__title {
    max-width: 6.2em;
    margin-right: 24px ;
  }
  /deep/.van-cell__value {
    display: flex;
    align-items: center;
  }
}
.radio-box {
  flex: 0;
  display: flex;
  align-items: center;
  .radio-item {
    flex: 0;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    line-height: 36px;
    white-space: nowrap;
    &:not(:last-child) {
      margin-right: 24px ;
    }
    .iconfont {
      margin-right: 8px;
      font-size: 36px;
      color: $color-text-disabled;
    }
    &.active {
      .iconfont {
        color: $color-primary;
      }
    }
  }
}
.map-list {
  width: 100%;
  .map-item {
    position: relative;
    width: 100%;
    padding: 24px ;
    background-color: $color-white;
    &:not(:last-child)::after {
      content: '';
      position: absolute;
      pointer-events: none;
      box-sizing: border-box;
      left: 24px ;
      right: 24px ;
      bottom: 0;
      border-bottom: 1px solid $color-border;
    }
    .map-item-name {
      line-height: 44px ;
      font-size: 28px;
      color: $color-text-main;
    }
    .map-item-desc {
      margin-top: 8px;
      line-height: 32px;
      font-size: 24px ;
      color: $color-text-sub;
    }
  }
}
.city-select {
  flex: 0 0 auto;
  padding-right: 24px ;
  color: $color-text-main;

  .city-select_text {
    display: inline-block;
    margin: 0 12px 0 4px ;
  }
  .city-select_icon {
    display: inline-block;
    font-size: 28px;
  }
}
</style>
