<!--
 * @Author: lcz
 * @Date: 2023-01-09 16:47:21
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-04-10 15:30:56
 * @Description: 桌位选择弹框
-->
<template>
  <popup-box :show="popupShow" position="bottom" popupStyle="height:80%" @onClose="onClose">
    <div class="wh-popup-container">
      <div class="wh-popup-header" v-if="title">
        <div class="wh-popup-header_title">{{ title }}</div>
      </div>
      <div class="wh-popup-body with-footer" :class="{ 'with-header': title }">
        <van-tabs class="w-tab-box" type="card" v-model="activeTabIndex" :ellipsis="false">
          <div v-for="(region, index) in regionList" :key="index">
            <van-tab>
              <template #title>
                <span>{{ region.regionName }}区</span>
              </template>
              <div class="tab-content">
                <div class="btn-box" v-for="(table, itemIndex) in region.tables" :key="itemIndex" v-show="excludedTableId !== table.tableId">
                  <button :class="{ active: table.tableId === selectedTable.tableId }" @click="checkTable(table)">
                    {{ table.tableName }}
                  </button>
                </div>
              </div>
            </van-tab>
          </div>
        </van-tabs>
      </div>
      <div class="wh-popup-footer">
        <btn-footer showLeftBtn :leftBtnText="leftBtnText" rightBtnText="确定" @onLeftClick="onClose" @onRightClick="onSubmit"></btn-footer>
      </div>
    </div>
  </popup-box>
</template>
<script>
import Vue from 'vue'
import { Tab, Tabs, Popup } from 'vant'
import { listStoreOrderTablesApi } from '@/api/order'
// import cloneDeep from 'lodash/cloneDeep'
import PopupBox from '@/components/common/PopupBox'
import BtnFooter from '@/components/common/BtnFooter'
Vue.use(Tab).use(Tabs).use(Popup)
export default {
  name: 'table-select-popup',
  components: { PopupBox, BtnFooter },
  props: {
    show: { type: Boolean, required: true, default: false },
    title: { type: String, required: false, default: '' },
    storeId: { type: String, required: true, default: '' },
    excludedTableId: { type: String, required: false, default: '' },
    leftBtnText: { type: String, required: false, default: '返回' },
    useComponentData: { type: Boolean, required: false, default: true },
    sourceData: { type: Array, required: false, default: () => [] }
  },
  data () {
    return {
      popupShow: false,
      //   storeId: '',
      regionList: [],
      defaultRegionList: [],
      activeTabIndex: 0,
      selectedTable: {}
    }
  },
  watch: {
    show (val) {
      if (val) {
        if (this.useComponentData) {
          //   this.storeId = localStorage.getItem('storeID')
          this.listStoreTables()
        } else {
          this.regionList = JSON.parse(JSON.stringify(this.sourceData))
        }
      }
      this.popupShow = val
    }
  },
  created () {},
  methods: {
    onOverlayClick () {},
    listStoreTables () {
      listStoreOrderTablesApi(this.storeId).then((res) => {
        if (res.data.status !== 0 || !res.data.data) {
          this.regionList = []
          return false
        }
        this.regionList = res.data.data
      })
    },
    checkTable (table) {
      this.selectedTable = table
    },
    onClose (e) {
      this.$emit('onClose', e)
    },
    onSubmit () {
      if (!this.selectedTable) {
        this.$toast('请先选择桌位')
        return false
      }
      this.$emit('onSubmit', this.selectedTable)
    }
  }
}
</script>
<style lang="scss" scoped>
.w-tab-box {
  position: relative;
  width: 100%;
  height: 100%;

  /deep/ .van-tabs__wrap {
    height: 124px;
    background-color: $color-white;
    border-bottom: 1px solid $color-border;
    box-shadow: 0 2px 4px  0 rgba(0, 0, 0, 0.1);

    .van-tabs__nav {
      box-sizing: border-box;
      height: 100px;
      margin: 0 20px;
      padding: 16px 0;
      border: none;

      .van-tab {
        max-width: 22%;
        height: 68px;
        padding: 6px 16px;
        background-color: $color-background;
        color: #666;
        font-size: 26px;
        border: 1px solid $color-background;
        border-radius: 8px;

        &:not(:last-child) {
          margin-right: 30px;
        }

        &.van-tab--active {
          color: $color-primary;
          border: 1px solid $color-primary;
          background-color: $color-white;
        }

        .number-tag {
          display: block;
          position: absolute;
          top: -1px;
          right: -4px;
          min-width: 32px;
          padding: 1px 6px;
          font-size: 20px;
          text-align: center;
          transform: scale(0.8);
          color: $color-white;
          background-color: #fd7022;
          border-radius: 50%;
        }
      }
    }
  }

  /deep/ .van-tabs__content {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
    background-color: $color-white;
    overflow-y: auto;
  }

  .tab-content {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 100%;
    padding: 12px 12px;
    margin-bottom: 20px;

    .btn-box {
      flex: 0 0 148px;
      padding: 12px;

      button {
        width: 100%;
        height: 120px;
        padding: 6px 10px;
        justify-content: center;
        font-size: 32px;
        font-weight: bold;
        background-color: $color-background;
        border: none;
        border-radius: 8px;
        overflow: hidden;

        &.active {
          color: $color-white;
          background-color: $color-primary;
        }
      }
    }
  }
}
</style>
