<!--
 * @Author: hwu
 * @Date: 2020-10-13 14:26:53
 * @Description: 自定义索引栏组件，带搜索
 * @LastEditTime: 2021-02-08 21:03:53
-->

<!--
 * @param show 是否显示
 * @param data 数据，结构如下
 * {
 *    key: 'A',
 *    value: 'A',
 *    children: [
 *      {
 *         key: '1',
 *         value: '安徽'
 *      }
 *    ]
 *   ]
 * }
 -->
<template>
  <div class="custom-popup-wraper" @touchmove.stop>
    <transition name="custom-popup">
      <div class="custom-popup-box" v-show="show">
        <div class="custom-popup-header">
          <div class="search-input">
            <search-input v-model="queryName" @input="filterData" placeholder="搜索名称"></search-input>
          </div>
          <div class="cancel-btn" @click="closePopup">取消</div>
        </div>
        <div class="custom-popup-body">
          <van-index-bar :sticky="false" :index-list="indexList" v-show="!queryResultShow">
            <div v-for="(group,index) in data" :key="index">
              <van-index-anchor :index="group.key" />
              <div class="custom-row-box" v-for="(item, itemIndex) in group.children" :key="itemIndex" @click="selectedItem(item)">
                <span>{{item.value}}</span>
              </div>
            </div>
          </van-index-bar>
          <div class="query-result-box" v-show="queryResultShow">
            <div class="custom-row-box no-border no-background" v-show="queryList.length > 0">找到{{queryList.length}}个</div>
            <div class="custom-row-box" v-for="(item, index) in queryList" :key="index" @click="selectedItem(item)">
              <span>{{item.value}}</span>
            </div>
            <div class="wh-empty-box" v-if="!queryList || queryList.length === 0">
              <img class="wh-empty-img" src="@/assets/images/empty-3.png">
              <p class="wh-empty-text">没有找到，请换个关键词试试</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { IndexBar, IndexAnchor } from 'vant'
import SearchInput from '@/components/common/SearchInput'
export default {
  name: 'popup-index-bar',
  components: { SearchInput: SearchInput, VanIndexBar: IndexBar, VanIndexAnchor: IndexAnchor },
  props: {
    show: { type: Boolean, required: true, default: false },
    data: { type: Array, required: false, default: () => [] }
  },
  data () {
    return {
      indexList: [],
      queryName: '',
      queryList: [],
      queryResultShow: false
    }
  },
  watch: {
    queryName (val, oldVal) {
      if (val !== oldVal) {
        this.filterData()
      }
    },
    show (val) {
      if (val) {
        this.indexList = this.data.map((x) => {
          return x.key
        })
      }
    }
  },
  methods: {
    filterData () {
      if (!this.queryName) {
        this.queryResultShow = false
        return false
      }
      const queryList = this.data
        .reduce((result, item) => {
          return result.concat(item.children)
        }, [])
        .filter((x) => x.value.indexOf(this.queryName) > -1)
      const unionList = []
      for (let item of queryList) {
        if (!unionList.some((x) => x.key === item.key)) {
          unionList.push(item)
        }
      }
      this.queryList = unionList
      this.queryResultShow = true
    },
    selectedItem (item) {
      this.$emit('onItemClick', item)
    },
    closePopup (e) {
      this.$emit('onCancelClick', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-popup {
  width: 100%;
  height: 100%;
  opacity: 1;
}
.custom-popup-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 750px;
  height: 100%;
  background-color: $color-background;
  z-index: 2001;
  .custom-popup-header {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    padding: 10px 20px;
    align-items: center;
    background-color: $color-white;
    border-bottom: 1px solid $color-border;
    z-index: 99;
    .search-input {
      flex: 1;
    }
    .cancel-btn {
      flex: 0 0 auto;
      padding-left: 12px;
      color: $color-primary;
    }
  }
  .custom-popup-body {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 100px;
    padding-bottom: 120px;
    overflow-y: auto;
    // -webkit-overflow-scrolling: touch;
  }
}
.custom-popup-enter-active,
.custom-popup-leave-active {
  transition: opacity 0.3s;
}
.custom-popup-enter,
.custom-popup-leave-to {
  opacity: 0;
}

.custom-row-box {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px 24px ;
  line-height: 40px;
  align-items: center;
  color: $color-text-main;
  background-color: $color-white;

  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    left: 24px ;
    right: 24px ;
    bottom: 0;
    border-bottom: 1px solid $color-border;
  }

  &.no-background {
    background-color: unset;
  }

  &.no-border:after {
    border-bottom: none !important;
  }

  &:not(.with-border):last-child:after {
    border-bottom: none !important;
  }

  .custom-row-left {
    flex: 1;

    span {
      vertical-align: middle;
    }
  }

  .custom-row-right {
    flex: 0;
    margin-left: 24px ;
  }
}

/deep/ .van-index-bar {
  .van-index-bar__index {
    margin-bottom: 16px;
    color: $color-primary;
  }
  .van-index-bar__index--active {
    color: $color-primary !important;
  }
  .van-index-anchor {
    color: $color-text-normal;
    font-size: 24px ;
    font-weight: normal;
  }
}
</style>
