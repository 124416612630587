<template>
  <div class="search-input-wrapper">
    <i class="search-icon iconfont iconsousuo" @click="clearVal"></i>
    <form action="" @submit.prevent="$closeKeyboard()">
      <input type="search" ref="searchInput" :value="value" @input="updateVal" @focus="focusInput" @blur="blurInput" @change="changeVal" :placeholder="placeholder">
    </form>
    <i class="clear-icon iconfont iconshanchu-tianchong" v-if="clearBtnShow" @mousedown.prevent @click.stop="clearVal"></i>
  </div>
</template>
<script>
export default {
  name: 'search-input',
  props: {
    value: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' }
  },
  data () {
    return {
      inputFocused: false
    }
  },
  computed: {
    clearBtnShow () {
      return this.inputFocused && this.value
    }
  },
  methods: {
    focusInput () {
      this.inputFocused = true
    },
    blurInput () {
      this.inputFocused = false
    },
    changeInput () {
      if (!this.value) {
        this.clearBtnShow = false
        return false
      }
      this.clearBtnShow = true
    },
    clearVal () {
      this.$refs.searchInput.value = ''
      this.$emit('input', this.$refs.searchInput.value)
    },
    updateVal (e) {
      this.$emit('input', e.target.value)
    },
    changeVal (e) {
      this.$emit('change', e.target.value)
    }
  }
}
</script>
<style lang="scss" scoped>
.search-input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  padding: 6px 6px 6px 25px;
  line-height: 44px ;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 56px;
  .search-icon {
    flex: 0;
    margin-right: 10px;
    color: $color-text-sub;
  }
  form {
    flex: 1;
    input {
      font-size: 24px ;
      background: unset;
      border: none;
      &::-webkit-search-cancel-button {
        //此处去掉默认的小×
        -webkit-appearance: none;
      }
    }
  }

  .clear-icon {
    flex: 0;
    padding: 0 10px;
    color: rgba($color-text-sub, 0.6);
  }
}
</style>
